import { provideHttpClient, withFetch, withInterceptors, withJsonpSupport } from '@angular/common/http';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    inject,
    provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import {
    MAT_PAGINATOR_DEFAULT_OPTIONS,
    MatPaginatorDefaultOptions,
    MatPaginatorIntl,
} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
    NavigationError,
    provideRouter,
    Router,
    TitleStrategy,
    withComponentInputBinding,
    withNavigationErrorHandler,
    withRouterConfig,
} from '@angular/router';
import {
    getBrowserSupportedLanguage,
    LanguageService,
    provideLanguageService,
    SetAcceptLanguageHeaderInterceptor,
    TranslocoHttpLoader,
} from '@icp/angular/i18n';
import { DefaultIdpStorage, provideIdpServiceConfig } from '@icp/angular/idp';
import { BASE_PATH } from '@icp/interfaces';
import { TranslocoService } from '@jsverse/transloco';
import { provideEffects } from '@ngrx/effects';
import { DEFAULT_ROUTER_FEATURENAME, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore, Store } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';

import { environment } from '../environments/environment';
import { routes } from './app-routes';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';
import * as AuthActions from './auth/store/auth.actions';
import { AuthEffects } from './auth/store/auth.effects';
import { AUTH_FEATURE_NAME, authFeature } from './auth/store/auth.feature';
import { DEFAULT_PAGE_EVENT } from './core/model';
import { PageTitleStrategy } from './core/routing/page-title.strategy';
import { MessageModalService } from './core/services';
import { IcpMatPaginatorIntl } from './shared/theming/mat-paginator-intl';
import { SharedActions } from './state/shared/shared.actions';
import { ECONOMY_STORAGE, SharedEffects } from './state/shared/shared.effects';
import { SHARED_FEATURE_NAME, sharedFeature } from './state/shared/shared.feature';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(MatDialogModule),
        provideAnimationsAsync(window.matchMedia('(prefers-reduced-motion: reduce)').matches ? 'noop' : 'animations'),
        provideHttpClient(
            withFetch(),
            withInterceptors([SetAcceptLanguageHeaderInterceptor(sharedFeature.selectCurrentLang), AuthInterceptor]),
            withJsonpSupport(),
        ),
        provideRouter(
            routes,
            withRouterConfig({ paramsInheritanceStrategy: 'always' }),
            withComponentInputBinding(),
            withNavigationErrorHandler(async (error: NavigationError) => {
                if (error.error instanceof TypeError && error.error.message.includes('.js')) {
                    const messageModalService = inject(MessageModalService);
                    const transloco = inject(TranslocoService);
                    const confirmed = await messageModalService.showConfirm({
                        title: transloco.translate('UPDATE_AVAILABLE'),
                        text: transloco.translate('APPLICATION_UPDATED_MUST_RELOAD'),
                        buttonCancel: transloco.translate('CANCEL'),
                        buttonConfirm: transloco.translate('RELOAD'),
                    });
                    if (confirmed) {
                        window.location.reload();
                    }
                    return;
                }
                console.error(error);
                return;
            }),
        ),
        provideEffects([AuthEffects, SharedEffects]),
        provideStore({
            [SHARED_FEATURE_NAME]: sharedFeature.reducer,
            [AUTH_FEATURE_NAME]: authFeature.reducer,
            [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
        }),
        provideStoreDevtools({
            name: 'Otzy Dashboard',
            maxAge: 25,
        }),
        provideRouterStore(),
        MatSnackBar,
        provideLanguageService({ selector: sharedFeature.selectCurrentLang, loader: TranslocoHttpLoader }),
        { provide: TitleStrategy, useClass: PageTitleStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: initApplication,
            deps: [Store, LanguageService, Sentry.TraceService, MatIconRegistry, DomSanitizer],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
                dialogOptions: { lang: getBrowserSupportedLanguage() },
            }),
        },
        { provide: Sentry.TraceService, deps: [Router] },
        { provide: MatPaginatorIntl, useClass: IcpMatPaginatorIntl },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' } satisfies MatFormFieldDefaultOptions,
        },
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
            useValue: {
                showFirstLastButtons: true,
                pageSizeOptions: [10, 20, DEFAULT_PAGE_EVENT.pageSize, 100, 200, 500],
                pageSize: DEFAULT_PAGE_EVENT.pageSize,
            } satisfies MatPaginatorDefaultOptions,
        },
        provideIdpServiceConfig({ serverUrl: '', clientId: environment.idpClientId, storage: DefaultIdpStorage }),
        { provide: BASE_PATH, useValue: '' },
        provideExperimentalZonelessChangeDetection(),
    ],
};

export function initApplication(
    store: Store,
    languageService: LanguageService,
    _: Sentry.TraceService,
    iconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
) {
    return () => {
        iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
        iconRegistry.addSvgIcon(
            'otzy_logo_with_text',
            domSanitizer.bypassSecurityTrustResourceUrl('/assets/logo-with-text.svg'),
        );
        const localStorageEconomy = localStorage.getItem(ECONOMY_STORAGE);
        if (localStorageEconomy) {
            store.dispatch(SharedActions.getDashboardEconomy({ economyId: JSON.parse(localStorageEconomy).id }));
        }
        store.dispatch(AuthActions.startAutoLogin());
        return languageService.initialize();
    };
}
